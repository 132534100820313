<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <img src="@/assets/images/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">
      <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">404 - Page Not Found!</h1>
      <p class="sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse">Sorry, we messed up!</p>
      <button class="logout" @click="logout">Login again</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout () {
      if (this.$auth.profile) this.$auth.logOut()
      this.$acl.change('guest')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('loggedIn')
      localStorage.removeItem('accessToken')
    }
  }
}
</script>

<style>
.bg-img {
  background-image: url("../../assets/images/vuexy-login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.logout {
  color: white;
  border: none;
  outline: none;
  font-size: 1.25rem;
  border-radius: 6px;
  padding: 1rem 2.5rem;
  background: #1c5bfe;
}
</style>